import axios from 'axios';
import I18n from 'i18n-js';
import merge from 'lodash/merge';
import { I18N_TRANSLATIONS_ENDPOINT, isTest } from 'students/constants';
import { DEFAULT_LOCALE, supportedLocalesWithFlags } from './config';

interface ISupportedLocale {
  lKey: string;
  flagSrc: any;
  labelKey: string;
  nativeName: string;
}

const ENGLISH_LOCALE = 'en';

export const getSupportedLocales = (): ISupportedLocale[] =>
  [...supportedLocalesWithFlags]
    .map(([lKey, localeInfo]) => ({
      lKey,
      flagSrc: localeInfo.flag,
      labelKey: `frontend.locale.locales.${lKey}`,
      nativeName: localeInfo.nativeName
    }))
    .filter(Boolean) as ISupportedLocale[];

export const getLocaleTranslation = (
  localeKey: string
): Promise<Record<string, any>> | undefined =>
  axios
    .get(`${I18N_TRANSLATIONS_ENDPOINT}/locales/${localeKey}`)
    .then((res) => res.data)
    .catch(() => {
      // Do nothing
    });

export const updateI18nTranslation = (
  locale: string,
  translations: Record<string, any> | null | undefined
): void => {
  if (translations) {
    I18n.translations[locale] = merge(I18n.translations[locale], translations[locale]);
  }
};

export function changeLocale(locale: string): void {
  const selectedLocale = locale || DEFAULT_LOCALE;

  // Do not make request if in testing
  if (isTest) return;

  try {
    // https://webpack.js.org/guides/code-splitting/#dynamic-imports
    import(
      /* webpackChunkName: "i18n/[request]", webpackMode: "lazy" */ `./translations/${selectedLocale}.json`
    )
      .then(({ default: _translation }) => {
        I18n.translations[selectedLocale] = _translation[selectedLocale];
        I18n.defaultLocale = selectedLocale;
        I18n.fallbacks = selectedLocale;
      })
      .then(async () => {
        const translationData = await getLocaleTranslation(selectedLocale);

        updateI18nTranslation(selectedLocale, translationData);
      })
      .catch(() => {
        if (selectedLocale !== ENGLISH_LOCALE) {
          changeLocale(ENGLISH_LOCALE);
        }
      });

    I18n.locale = selectedLocale;
  } catch (_e) {
    // Do nothing
  }
}
