import * as Sentry from '@sentry/react';
import { SENTRY_DSN } from 'students/constants/envs';

const replay = Sentry.replayIntegration({
  maskAllText: true,
  blockAllMedia: true
});

const feedbackIntegration = Sentry.feedbackIntegration({
  colorScheme: 'system',
  autoInject: false
});

const sentryBaseConfig = {
  dsn: SENTRY_DSN,
  integrations: [replay, feedbackIntegration],
  maxBreadcrumbs: 10,
  environment: import.meta.env.MODE,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.01
};

export default sentryBaseConfig;
