import { createSelector, Selector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import {
  userSharedSelectors,
  tSharedAppState
} from '@adeptlms/lingu-students-react-shared';

const selectLesson: Selector<tSharedAppState, tSharedAppState['shared']['lesson']> = (
  state
) => state.shared.lesson;

const selectSortedTargetLangs: Selector<
  tSharedAppState,
  ReturnType<typeof userSharedSelectors.selectTargetLangs>
> = createSelector(
  [selectLesson, userSharedSelectors.selectTargetLangs],
  (lesson, targetLangs) =>
    sortBy(targetLangs, (lang) => (lang.code === lesson.data?.languageCode ? -1 : +1))
);

const userSelectors = {
  ...userSharedSelectors,
  selectSortedTargetLangs
};

export default userSelectors;
